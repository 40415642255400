import React from 'react'
import Layout from '../layouts'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import SEO from '../components/seo'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { HeroBackground, Section, Title } from '@sites/shared-ui'
import { Icon } from '@sites/shared-ui'
import { Box } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'

const useStyles = makeStyles(theme => ({
  copyright: {
    display: 'flex !important',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
      textAlign: 'center',
    },
  },
  backgroundContainer: {
    backgroundImage: `url("/footer-right.jpeg")`,
    backgroundPosition: '100% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: 80,
    height: '100%',
  },
  home__bg: {
    objectFit: 'cover',
  },
  mapContaniner: {
    width: '100%',
    height: '100vh',
    boxShadow: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      height: 250,
    },
  }
}))

const Contact = () => {
  const classes = useStyles()

  return (
      <Layout footer="false">
        <SEO title="Contact Us" />
        <Section fullWidth disablePadding>
          <Grid container alignItems="stretch">
            <Grid item xs={12} md={6}>
              <iframe
                className={classes.mapContaniner}
                src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d117459.97852367048!2d72.46728576439034!3d23.074230464075907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m3!3m2!1d23.0872399!2d72.4849859!4m0!5e0!3m2!1sen!2sin!4v1627624922586!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={classes.backgroundContainer}>
                <Typography variant="h6" gutterBottom>
                  CONTACT
                </Typography>
                <Typography
                  style={{ marginBottom: 16, fontWeight: 'bold' }}
                  variant="h2"
                >
                  Get In Touch
                </Typography>
                <div style={{ height: '100%' }}>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    We are here to help you. If you need more information about
                    our company or our projects. Please do not hesitate to
                    contact us
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    LOTUS GROUP
                  </Typography>
                  <div style={{ marginBottom: 16 }}>
                    <Typography variant="subtitle1">
                      LOTUS HEIGHTS, NEAR.GEBANSHA, ISANPUR, AHMEDABAD 382443
                    </Typography>
                    <Typography variant="subtitle1">
                      Nr. Police Station, Dudheshwar,
                    </Typography>
                    <Typography variant="subtitle1">Ahmedabad.</Typography>
                  </div>
                  <Typography variant="h6" gutterBottom>
                    Contact
                  </Typography>
                  <Typography variant="subtitle1">
                    Phone: +91 9265765215
                  </Typography>
                  <Typography variant="subtitle1">
                    Email: lotusheights1@gmail.com
                  </Typography>
                </div>
                <Typography variant="h6" gutterBottom>
                  Any Questions? Inquiry? Interested in seeing a sample?
                </Typography>
                <Typography variant="subtitle1">
                  Send us a message and we'll get back to you as soon as
                  possible.
                </Typography>
                <IconButton
                  href="https://wa.me/919737517557"
                  aria-label="whatsapp"
                  size="medium"
                >
                  <Icon name="whatsapp" size={44} />
                </IconButton>
                <IconButton
                  href="tel:+919737517557"
                  aria-label="call"
                  size="medium"
                >
                  <Icon name="phone" size={32} />
                </IconButton>
                <IconButton
                  href="mailto:hello@thelotusgroup.co"
                  aria-label="call"
                  size="medium"
                >
                  <Icon name="envelope" size={36} />
                </IconButton>
              </div>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Layout>
    )
}

export default Contact
